<template>
  <div class="home">
    <!--<h3 class="question">Ciao! Cosa desideri fare?</h3>-->

    <ul class="action-boxes">
      <li>
        <a href="#" @click="goToLeave($event)">
          <div class="img">
            <img
              src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGAAAABgCAYAAADimHc4AAAABmJLR0QA/wD/AP+gvaeTAAABj0lEQVR4nO3dT0rDQBxH8a9V9C6CJwkUPIHgLbvTq7gX/23UTV10CqLYNG0yzyHvA7PpLDL5vSHbJpIktadL8lBWB59ldrok70nWZX0muUZPNCM/h2+Eiv4avhEq6Bu+ESa07/CNMIGhwzfCiA4dvhFGcOzwjXCEsYZvhAOMPXwjDDDV8I2wh2U2A5pq+N8jLCu9UzPOkjxl+uFv12N5Jm5BH6BYJDmv+LyLJCcVn9eE22xuZo3bf1PpnXq1eAvWPftNvdN/+QTNlgFgBoAZAGYAmAFgBoAZAGYAmAFgBoAZAGYAmAFgBoAZAGYAmAFgBoAZAGYAmAFgBoAZAGYAmAFgBoAZAGYAmAFgBoAZAGYAmAFgBoAZAGYAmAFgBoAZAGYAmAFgBoAZAGYAmAFgBoAZAGYAmAFgBoAZAGYAmAFgBoAZAGYAmAFgBoAZAGYAmAFgBoAZAGYAmAFgBoC1GOBlx95ztVOMpMUA9zv27qqdYsYuk7zl999TvZY9VXCVZJXko6xV+U2VnZYlSRruC8n3Ab144i1cAAAAAElFTkSuQmCC"
            />
          </div>
          <div class="title">Deposito</div>
        </a>
      </li>
      <li>
        <a href="#" @click="goToReceive($event)">
          <div class="img">
            <img
              src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGAAAABgCAYAAADimHc4AAAABmJLR0QA/wD/AP+gvaeTAAABpUlEQVR4nO3dS0rDYBhG4bfeNuJUENdREFyC4AbFqbgW3UAHtogWb4OmUNTy29xOQ84DP3RS8uU7CR02kSTMIT1AQ0dJJkm+6EHG5iLJQ5KPJO9J7pOcoxONyGmSeVZP/eaZJzkD5xqN2/xe/vrcgXONxiLbAzyDc9UyoQeoofSDO6h7OqAHGDsDwAwAMwDMADADwAwAMwDMADADwAwAMwDMADADwAwAMwDMADADwAwAMwDMADADwAwAMwDMADADwAwAMwDMADADwAwAMwDMADADwAwAMwDMADADwAwAMwDMADADwAwAMwDMADADwAwAMwDMADADwAwAMwDMADADwAwAMwDMADADwAwAMwDMADADwAwAMwDMADADwAwA26cAN0lm2f4HPetTUvr+LMl1y7MP3kmSl5SX19ZZJDnu5c4K9uUN+Ezy2uP13qprasNlkmW6f/qX1bX0h2lWb0KXy7/q7W4GqqsILn8HbUdw+TW0FcHlN9A0gstvQd0ILr9Fu0Zw+R34bwSX36FSBJffg20RXH6PfkZw+YBpkqckj9VnSZIG5RusQgqiMUyZZAAAAABJRU5ErkJggg=="
            />
          </div>
          <div class="title">Ritiro</div>
        </a>
      </li>
    </ul>
  </div>
</template>

<style lang="scss" scoped>
.home {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 20px;
  width: 100%;

  h3.question {
    font-size: 42px;
    padding: 30px;
    font-family: "Anton";
    background-color: #ebebeb;
    color: #222;
    margin-bottom: 30px;

    &.locker-id-question {
      margin-bottom: 10px;
    }
  }

  .action-boxes {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin: 20px;
    width: 100%;

    li {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      a {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        text-decoration: none;
        color: #fff;
        background-color: #ddd;
        margin: 10px;
        width: 100%;

        .img img {
          display: block;
          width: 240px;
          padding: 10px;
          margin-top: 20px;
        }

        .title {
          display: flex;
          justify-content: center;
          align-items: center;
          background-color: #00838f;
          padding: 20px;
          color: #fff;
          width: 100%;
          text-align: center;
          margin-top: 20px;
          justify-self: flex-end;
          font-size: 38px;
        }
      }
    }
  }
}
</style>

<script>
import lockerConfig from "@/lockerConfig";

export default {
  name: "Home",
  data() {
    return {
      lockerId: localStorage.lockerId,
    };
  },
  methods: {
    goToLeave(event) {
      event.preventDefault();
      this.$router.push({
        path: "/leave",
        query: { action: "leave" },
      });
    },
    goToReceive(event) {
      event.preventDefault();
      this.$router.push({
        path: "/home2",
        query: { action: "receive" },
      });
    },
  },
};
</script>
