import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import { login } from './auth/lockerApi'
import lockerConfig from './lockerConfig'

import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';

import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faCircleUp, faCircleDown } from '@fortawesome/free-regular-svg-icons';
import { faGear, faUserPlus } from '@fortawesome/free-solid-svg-icons';

library.add(faCircleUp, faCircleDown, faGear, faUserPlus);

(async () => {
    createApp(App).use(VueSweetalert2).use(router).component('font-awesome-icon', FontAwesomeIcon).mount('#app');
})();
