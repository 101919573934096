import axios from 'axios';
import lockerConfig from '../lockerConfig';

const login = (lockerId, password) => new Promise(async (resolve, reject) => {
    try {
        const result = await axios.post(`${lockerConfig.apiBase}/lockers/login`, {
            lockerId: lockerId,
            password: password
        }, { timeout: 2000 });

        console.log("Login success", result.data.message);

        localStorage.apiToken = result.data.message;

        return resolve();
    } catch (error) {
        console.log('Login error', error);
        return reject();
    }
});

export { login };