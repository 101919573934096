<template>
  <header>
    <section class="logo">
      <router-link to="/">
        <img src="@/assets/mls-logo.png" />
      </router-link>
      <router-link to="/">
        <img src="@/assets/onniversum-logo.png" />
      </router-link>
    </section>
    <section class="ret-home-btn">
      <template v-if="isHome">
        <router-link to="/register">
          <span>
            <font-awesome-icon icon="fas fa-user-plus"> </font-awesome-icon>
            Register
          </span>
        </router-link>

        <span class="locker-id">Locker ID: {{ lockerId }}</span>

        <router-link to="/login">
          <span>
            <font-awesome-icon icon="fas fa-gear"> </font-awesome-icon> Login
          </span>
        </router-link>
      </template>
      <template v-else>
        <!--
        <a href="#" @click="$router.go(-1)">
          <span>Back</span>
        </a>
        <router-link to="/">
          <span>Home</span>
        </router-link>
        -->
        <router-link to="/">
          <span>Cancel</span>
        </router-link>
      </template>
    </section>
    <section class="time">
      <span @click="handleCounter">{{ dateTime }}</span>
    </section>
  </header>
  <main>
    <router-view />
  </main>
</template>

<style lang="scss">
@import "https://cdnjs.cloudflare.com/ajax/libs/meyer-reset/2.0/reset.min.css";
@import url("https://fonts.googleapis.com/css2?family=Open+Sans&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Anton&display=swap");

* {
  box-sizing: border-box;
  user-select: none;
  border: 0;
  padding: 0;
  margin: 0;
}

body {
  font-family: "Open Sans", "Arial", sans-serif;
}

header {
  background-color: #fff;
  box-shadow: 0 5px 10px #ebebeb;
  width: 100%;
  padding: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  .logo {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;
    justify-items: center;
    align-items: center;
    align-content: center;
    img {
      display: block;
      width: 140px;
    }

    @media all and (max-width: 1110px) {
      grid-gap: 10px;
      img {
        width: 100px;
      }
    }
  }

  .ret-home-btn {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    a {
      margin: 0 15px;
      color: #fff;
      text-decoration: none;
      // background-color: #f07e26;
      background-color: red;
      display: block;
      padding: 15px;
      border-radius: 5px;
      font-size: 24px;

      @media all and (max-width: 1110px) {
        padding: 10px;
        font-size: 22px;

        margin: 0 5px;
      }

      @media all and (max-width: 900px) {
        padding: 10px;
        font-size: 18px;

        margin: 0 5px;
      }

      @media all and (max-width: 800px) {
        padding: 8px;
        font-size: 16px;

        margin: 0 5px;
      }

      @media all and (max-width: 700px) {
        font-size: 14px;
      }
    }

    span.locker-id {
      margin: 0 15px;
      color: #fff;
      text-decoration: none;
      background-color: #f07e26;
      display: block;
      padding: 15px;
      border-radius: 5px;
      font-size: 36px;

      @media all and (max-width: 1000px) {
        padding: 10px;
        font-size: 22px;

        margin: 0 5px;
      }

      @media all and (max-width: 900px) {
        padding: 10px;
        font-size: 18px;

        margin: 0 5px;
      }

      @media all and (max-width: 800px) {
        padding: 8px;
        font-size: 16px;
        margin: 0 5px;
      }

      @media all and (max-width: 700px) {
        font-size: 14px;
      }
    }
  }

  .time {
    font-size: 18px;
    font-family: "Courier New";
    font-weight: bold;

    @media all and (max-width: 800px) {
      font-size: 14px;
    }
  }
}

main {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px;
  width: 100%;
}
</style>

<script>
import apiService from "@/api/service.js";

export default {
  name: "Main",
  data() {
    return {
      dateTime: "",
      counter: 0,
      lastCountUp: 0,
      lockerId: localStorage.lockerId,
    };
  },

  methods: {
    updateClock: function () {
      const dateTime = new Date().toLocaleString("tr-TR");
      this.dateTime = dateTime.substring(0, dateTime.length - 3);

      apiService.pingServer().then((data) => {
        console.log("Server is updated!");

        if (data.shouldReload) {
          window.location = "/?v=" + Date.now();
        }
      });

      if (!localStorage.lockerId || !localStorage.password) {
        this.$router.push("/noAuth");
      }
    },
    handleCounter: function () {
      if (Date.now() - this.lastCountUp >= 1000) this.counter = 0;

      this.counter++;
      this.lastCountUp = Date.now();

      if (this.counter >= 10) {
        this.$router.push("/configuration");
      }
    },
  },

  mounted() {
    this.counter = 0;

    // Fetch lockerId and password from GET params
    const urlParams = new URLSearchParams(window.location.search);
    const lockerId = urlParams.get("id");
    const password = urlParams.get("password");

    console.log("lockerId: ", lockerId);
    console.log("password: ", password);
    console.log(this.$route.query);

    // If lockerId and password are not null, then save them to localStorage.
    if (lockerId && password) {
      localStorage.setItem("lockerId", lockerId);
      localStorage.setItem("password", password);

      window.location = "/";
    } else if (!localStorage.lockerId || !localStorage.password) {
      this.$router.push("/noAuth");
    }

    // Update clock every x seconds.
    this.updateClock();
    setInterval(this.updateClock, 5000);
  },

  computed: {
    isHome: function () {
      return this.$route.name === "Home";
    },
  },
};
</script>
